
@media (min-width: 1200px){
    ::v-deep{
        .gnb__sub {
            left: 0;
        }
    }
}
@media (min-width: 1200px){
    ::v-deep{
        .header__gnb{
            padding-left: 140px;
            justify-content: flex-start;
            --gnb-padding-x: 16px
        }
    }
}
@media (min-width: 1620px){
    ::v-deep{
        .header__body>.container{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .header__logo,
        .header__right{
            position: relative;
        }
        .header__logo{
            left: 0;
            top: 0;
            transform: translate(0, 0);
        }
        .header__gnb{
            padding-left: 0;
            justify-content: center;
            --gnb-padding-x: 42px
        }
    }
}
