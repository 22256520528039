
.header__right{
    &-link{
        display: block;
        font-size: 1.6rem;
        color: #fff;
        transition: all ease-out .15s;
        &:hover{
            color: var(--v-primary-base) !important;
        }
    }
}
.header:hover,
.header.scroll-active{
    .header__right{
        &-link{
            color: var(--v-grey-base);
        }
    }
}
.line{
    display: block;
    width: 1px;
    height: 12px;
    background: var(--v-grey-lighten3);
}
