
.product{
    &__inner{
        width: 100%;
        height: 100%;
        padding: 30px 12px;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .product{
        &__inner{
            padding: 50px 12px;
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
    .product{
        &__inner{
            padding: 100px 30px;
        }
    }
}
@media (min-width:1700px){
    .product{
        &__inner{
            padding: 160px;
            &--product{
                padding: 160px 120px;
            }
        }
    }
}

