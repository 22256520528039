
// btn-top-primary
.btn-top-primary {
    transition: all ease-out 0.2s;
    box-shadow: var(--shadow-elevation-6) rgba(var(--shadow-color), var(--shadow-opacity)) !important;
    will-change: unset !important;
    &.v-btn:not(.v-btn--has-bg) {
        background-color: #fff !important;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
