
.v-btn-outlined-tile-arrow{
    box-sizing: border-box;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    transition: 0.15s ease-out;
}
.v-btn-outlined-tile-arrow:not(:hover){
    padding: 0;
}
.v-btn__content-inner{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .v-application .v-btn.v-btn:not(.v-btn--has-bg).v-btn-outlined-tile-arrow:hover{
        background-color: var(--v-primary-base) !important;
        border-color: var(--v-primary-base);
        color: #fff !important;
    }
}
@media (min-width:1200px){
}

