
// main-visual
.main-visual {
    width: 100%;
    &__inner {
        display: block;
        height: 100vh;
        display: flex;
        align-items: center;
    }
    &__bg{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &__contents{
        width: 100%;
        position: relative;
    }
    &__btn{
        position: absolute;
        z-index: 5;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        height: 80px;
        background-color: rgba($color: #000, $alpha: .3);
        display: none;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 11px;
        font-weight: 600;
        color:  rgba($color: #fff, $alpha: .7);
        transition: 0.1s ease-out;
        &--prev{
            left: 0;
            border-radius: 0 999px 999px 0;
        }
        &--next{
            right: 0;
            border-radius: 999px 0 0 999px;
        }
    }
    &__title{
        max-width: 240px;
        background-size: contain;
    }
    .swiper-control{
        bottom: 40px;
        max-width: calc(var(--container) + var(--container-gutter) * 2);
        .swiper-pagination.swiper-pagination-bullets{
            position: relative;
            left: 0;
            bottom: 0;
            align-items: center;
            margin-right: -12px;
        }
    }
}
::v-deep{
    .swiper-pagination-bullet{
        width: 16px;
        height: auto;
        background-color: transparent !important;
        font-size: 1.2rem;
        font-weight: 700;
        text-align: left;
        color: rgba($color: #fff, $alpha: .4);
        border: 0;
        margin: 0 12px 0 0;
        padding-right: 0;
        transition: 0.25s ease-out;
        opacity: 1;
        &::after{
            width: 24px;
            height: 1px;
            background-color: #fff;
            position: absolute;
            border-radius: 0;
            top: 50%;
            right: 0;
            transform: translateY(-50%) scaleX(0);
            transform-origin: right center;
            transition: 0.25s ease-out;
        }
        &-active{
            width: 64px;
            color: #fff;
            &::after{
                transform: translateY(-50%) scaleX(1);
            }
        }
    }
}
.line{
    display: block;
    width: 1px;
    height: 16px;
    background-color: #fff;
}
@media (min-width: 576px) {
    .main-visual {
        &__title{
            max-width: 320px;
        }
    }
}
@media (min-width: 768px) {
    .main-visual {
        &__title{
            max-width: 500px;
        }
        .swiper-control{
            bottom: 80px;
            .swiper-pagination.swiper-pagination-bullets{
                justify-content: flex-start;
                margin-right: -24px;
            }
        }
    }
    ::v-deep{
        .swiper-pagination-bullet{
            width: 20px;
            font-size: 1.4rem;
            margin: 0 24px 0 0;
            &::after{
                width: 40px;
            }
            &-active{
                width: 84px;
            }
        }
    }
}
@media (min-width: 1024px) {
    .main-visual {
        &__btn:hover{
            background-color: var(--v-primary-base);
            color: #fff;
        }
        &__title{
            max-width: 700px;
        }
    }
}
@media (min-width: 1300px) {
    .main-visual {        
        &__btn{
            display: flex;
        }
    }
}
@media (min-width: 1740px) {
    .main-visual {        
        .container,
        .swiper-control {
            max-width: calc(var(--container-lg) + var(--container-gutter)*2);
        }
    }
}
