
.inquiry-card{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.3);
    transition: 0.15s ease-out;
    .v-btn{
        transition: 0.15s ease-out;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){    
    .inquiry-cards{
        width: 400px;
    }
}
@media (min-width:1024px){    
    .inquiry-cards{
        width: 500px;
    }
    .inquiry-card{
        &:hover{
            border-color: #fff;
            .v-btn.v-btn--outlined.white--text{
                background-color: var(--v-primary-base) !important;
                border-color: var(--v-primary-base) !important;
            }
        }
    }
}
@media (min-width:1200px){    
    .inquiry-cards{
        width: 652px;
    }
}

