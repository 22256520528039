
.footer{
    padding: 30px 0;
    background-color: var(--v-grey-darken4);
    font-family: var(--font-secondary);
    color: #fff;
    font-size: 1.4rem;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .footer{
        padding: 48px 0;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

