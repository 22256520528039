
.quick-menu {
    position: fixed;
    z-index: 50;
    bottom: 12px;
    right: 12px;
    &__toggle {
        position: relative;
        &-inner {
            position: absolute;
            top: 0;
            right: 0;
            transform: translateY(-100%);
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            opacity: 0;
            visibility: hidden;
            transition: 0.15s ease-out;
            .v-btn {
                margin-bottom: 8px;
                opacity: 0;
                visibility: hidden;
                transform: translateY(150%);
                transition: 0.15s ease-out;
            }
        }
        &-btn {
            background-color: rgba(0, 0, 0, 0.6) !important;
            transform: rotate(0);
            transition: 0.15s ease-out;
        }
        &.active {
            .quick-menu__toggle-btn {
                background-color: var(--v-primary-base) !important;
                transform: rotate(45deg);
            }
            .quick-menu__toggle-inner {
                opacity: 1;
                visibility: visible;
                .v-btn {
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                    &:nth-child(1) {
                        transition: opacity 0.21s 0.4s ease-out, visibility 0.21s 0.4s ease-out, transform 0.21s 0.4s ease-out, background-color 0.15s ease-out, color 0.15s ease-out;
                    }
                    &:nth-child(2) {
                        transition: opacity 0.21s 0.3s ease-out, visibility 0.21s 0.3s ease-out, transform 0.21s 0.3s ease-out, background-color 0.15s ease-out, color 0.15s ease-out;
                    }
                    &:nth-child(3) {
                        transition: opacity 0.21s 0.2s ease-out, visibility 0.21s 0.2s ease-out, transform 0.21s 0.2s ease-out, background-color 0.15s ease-out, color 0.15s ease-out;
                    }
                    &:nth-child(4) {
                        transition: opacity 0.21s 0.1s ease-out, visibility 0.21s 0.1s ease-out, transform 0.21s 0.1s ease-out, background-color 0.15s ease-out, color 0.15s ease-out;
                    }
                    &:nth-child(5) {
                        transition: opacity 0.21s ease-out, visibility 0.21s ease-out, transform 0.21s ease-out, background-color 0.15s ease-out, color 0.15s ease-out;
                    }

                    &.kakao-btn {
                        background-color: #fae100 !important;
                        color: var(--v-grey-darken4) !important;
                        .v-image {
                            max-width: 28px;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .quick-menu {
        bottom: 40px;
        right: 40px;
        &__toggle {
            &-inner {
                .v-btn {
                    margin-bottom: 12px;
                    &.kakao-btn {
                        .v-image {
                            max-width: 34px;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1024px) {
    .quick-menu {
        &__toggle {
            &-inner {
                .v-btn:hover {
                    background-color: #fff !important;
                    color: var(--v-primary-base) !important;
                    box-shadow: var(--shadow-elevation-6) rgba(var(--shadow-color), var(--shadow-opacity)) !important;
                }
            }
            &-btn:hover {
                background-color: var(--v-primary-base) !important;
            }
        }
    }
}
@media (min-width: 1200px) {
}
